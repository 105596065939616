import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactSelect from "react-select";
import { Badge, Table, UncontrolledTooltip } from "reactstrap";
import BarLoader from "react-spinners/BarLoader";

import Templete from "../../Templete/Templete";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

import OrderReviewDialog from "./OrderReviewDialog";
import ViewPlansDialog from "./ViewPlansDialog";

const RecentTransactions = (props) => {
  const operators = [
    { value: 1, label: "Airtel", image: "/images/airtel_logo.png" },
    { value: 2, label: "BSNL Topup", image: "/images/bsnl_logo.png" },
    { value: 3, label: "BSNL Special", image: "/images/bsnl_logo.png" },
    { value: 4, label: "JIO", image: "/images/jio_logo.png" },
    { value: 5, label: "Vi", image: "/images/vi_logo.png" },
    {
      value: 6,
      label: "Airtel Digital TV",
      image: "/images/airtel_digital_tv_logo.png",
    },
    { value: 7, label: "Dish TV", image: "/images/dish_tv_logo.png" },
    { value: 8, label: "Sun Direct", image: "/images/sun_direct_logo.png" },
    { value: 9, label: "Tata Sky", image: "/images/tata_sky_logo.png" },
    {
      value: 10,
      label: "Videocon D2H",
      image: "/images/videocon_d2h_logo.png",
    },
    {
      value: 47,
      label: "Google Play",
      image: "/images/google-play-icon-2.png",
    },
  ];

  const getLogo = (row) => {
    let obj = operators.find((o) => o.label === row.product.name);
    if (obj) {
      return obj.image;
    }
    return "";
  };
  return (
    <React.Fragment>
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title">Recent Transactions</h3>
          <div class="card-tools">
            <button
              class="btn btn-sm"
              style={{ backgroundColor: "transparent" }}
              onClick={() => {
                props.recentTransactions();
              }}
            >
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="card-body">
              {props.loading && (
                <div className="mb-2">
                  <BarLoader
                    color="#42ba96"
                    loading={props.loading}
                    width="100%"
                  />
                </div>
              )}

              {!props.loading && (
                <div className="table-responsive">
                  <Table className="align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">TXN Status</th>
                        <th scope="col">Operator</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data &&
                        props.data.map((result) => {
                          return (
                            <tr>
                              {/* <UncontrolledTooltip
                                placement="top"
                                target="tooltipTop"
                              >
                                {result.description}
                              </UncontrolledTooltip> */}
                              <td>
                                {(() => {
                                  if (result.status === "SUCCESS") {
                                    return (
                                      <h5 id="tooltipTop">
                                        <Badge color="success">SUCCESS</Badge>
                                      </h5>
                                    );
                                  } else if (result.status === "FAILED") {
                                    return (
                                      <h5 id="tooltipTop">
                                        <Badge color="danger">FAILED</Badge>
                                      </h5>
                                    );
                                  } else if (result.status === "PENDING") {
                                    return (
                                      <h5 id="tooltipTop">
                                        <Badge color="warning">PENDING</Badge>
                                      </h5>
                                    );
                                  } else if (result.status === "REVERSAL") {
                                    return (
                                      <h5>
                                        <Badge color="dark">REVERSAL</Badge>
                                      </h5>
                                    );
                                  } else if (result.status === "REFUND") {
                                    return (
                                      <h5 id="tooltipTop">
                                        <Badge color="danger">REFUND</Badge>
                                      </h5>
                                    );
                                  }
                                })()}
                              </td>
                              <td>
                                <span>
                                  <img
                                    src={getLogo(result)}
                                    alt="logo"
                                    height={40}
                                  />
                                  <br />
                                  <small>{result.created_on}</small>
                                </span>
                              </td>
                              <td>
                                Amount:
                                <b style={{ fontSize: "16px" }}>
                                  {" "}
                                  ₹{result.order_amount}
                                </b>
                                <br />
                                <small>
                                  Commission: ₹{result.commercial_amount}
                                </small>
                              </td>
                              <td>
                                <b style={{ fontSize: "16px" }}>
                                  {result.optional1}
                                </b>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecentTransactions;
