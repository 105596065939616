import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";
import IconButton from "@mui/material/IconButton";
import ViewListIcon from "@mui/icons-material/ViewModule";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import UserService from "../../../services/Admin/UserService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import UserDetailsDialog from "./UserDetails/UserDetailsDialog";
import { states } from "../../../utill/Common";

const UserList = () => {
  const loadingCtx = useContext(LoadingContext);

  const fetch = async () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _kycStatus = params.get("kyc_status");

    if (_kycStatus) {
      setKycStatus(_kycStatus);
      await fetchUsers(_kycStatus);
    }
  };

  // const [fromDate, setFromDate] = useState(
  //   new Date().toISOString().slice(0, 10)
  // );
  // const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [kycStatus, setKycStatus] = useState("");
  const [status, setStatus] = useState();
  const [userType, setUserType] = useState();
  const [state, setState] = useState();

  const [id, setId] = useState();
  const [userId, setUserId] = useState();
  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState();
  const [count, setCount] = useState();
  const [data, setData] = useState();

  const size = 10;

  useEffect(() => {
    fetch();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchUsers = async (_kycStatus) => {
    const token = AuthService.getAccessToken();

    setLoading(true);

    UserService.fetchAll(
      token,
      _kycStatus ? _kycStatus : kycStatus,
      status,
      userType,
      state,
      id,
      userId,
      searchText,
      size,
      page
    )
      .then((responseData) => {
        if (responseData.status !== "SUCCESS") {
          throw new Error(responseData.message);
        }

        const dataResult = responseData.data.result;
        setCount(responseData.data.count);
        setData(dataResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedData, setSelectedData] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "User Type",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.user_type}</span>
        </div>
      ),
    },
    {
      name: "User ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {(() => {
            return (
              <div>
                <span
                // onClick={() => {
                //   setUserData(row);
                //   setOpenUserDetailsDialog(true);
                // }}
                >
                  {row.user_id}
                </span>
                <br />
                <small>ID: {row.id}</small>
              </div>
            );
          })()}
        </div>
      ),
    },
    {
      name: "Name",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.name}</span>
        </div>
      ),
    },
    {
      name: "Email",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.email}</span>
        </div>
      ),
    },
    {
      name: "Mobile",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.mobile}</span>
        </div>
      ),
    },
    {
      name: "Balance",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>
            Main Wallet:{" "}
            <span
              style={{ color: "green", fontWeight: "bold", fontSize: "14px" }}
            >
              ₹
              {row.tbl_wallet_balance
                ? row.tbl_wallet_balance.main_wallet
                : "null"}
            </span>
          </span>{" "}
          <br />
          <span>
            Settlement Wallet:{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}
            >
              ₹
              {row.tbl_wallet_balance
                ? row.tbl_wallet_balance.settlement_wallet
                : "null"}
            </span>
          </span>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onClick={() => {
            changeStatusHandler(row.id);
          }}
        >
          {(() => {
            if (row.status === "Active") {
              return (
                <span class="badge badge-pill badge-success">{row.status}</span>
              );
            } else if (row.status === "Inactive") {
              return (
                <span class="badge badge-pill badge-danger">{row.status}</span>
              );
            } else {
              return (
                <span class="badge badge-pill badge-warning">{row.status}</span>
              );
            }
          })()}

          {/* {(() => {
            if (row.is_signup === "YES") {
              return <small>...</small>;
            }
          })()} */}
        </div>
      ),
    },
    {
      name: "Reg Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.created_on}</span>
        </div>
      ),
    },
    {
      name: "More Action",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => {
              handleClick(event);
              setSelectedData(row);
            }}
          >
            <MenuOpenIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setUserData(row);
              setOpenUserDetailsDialog(true);
            }}
          >
            <ViewListIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/user_profile/" + row.user_id);
            }}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              history.push("/admin/wallet_topup/" + row.user_id);
            }}
          >
            <WalletIcon color="secondary" />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={async () => {
              Swal.fire({
                title: "Are you sure you want to delete account?",
                text: `User ID: ${row.user_id} / Name: ${row.name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonColor: "red",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteUserHandler(row.id);
                }
              });
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                //  autoLoginHandler(selectedData.user_id);
              }}
            >
              Login to User Panel
            </MenuItem>

            <MenuItem
              onClick={() => {
                window.open(
                  "/admin/user_details/" + selectedData.user_id,
                  "_blank"
                );
              }}
            >
              Print User Details
            </MenuItem>

            <MenuItem
              onClick={() => {
                history.push(
                  "/admin/reset_login_details/" + selectedData.user_id
                );
              }}
            >
              Reset Login Details
            </MenuItem>

            <MenuItem
              onClick={() => {
                history.push("/admin/send_wp_message/" + selectedData.id);
              }}
            >
              Send Whatsapp Msg
            </MenuItem>

            {userType === "Admin" && (
              <MenuItem
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure to delete?",
                    showCancelButton: true,
                    confirmButtonText: `Delete`,
                    confirmButtonColor: "red",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      //    deleteUserHandler(selectedData.id);
                    }
                  });
                }}
              >
                Delete User
              </MenuItem>
            )}
          </Menu>
        </div>
      ),
      // ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  // const autoLoginHandler = async (userId) => {
  //   loadingCtx.startLoading();

  //   try {
  //     const token = AuthService.getAccessToken();

  //     const responseData = await AgentsService.autoLogin(token, userId);
  //     const status = responseData.status;
  //     const message = responseData.message;

  //     if (status === "SUCCESS") {
  //       const accessToken = responseData.data.access_token;
  //       const user = responseData.data.user;

  //       localStorage.setItem("access_token", accessToken);
  //       localStorage.setItem("user", user);

  //       window.open("/user/dashboard", "_blank", "");
  //     } else {
  //       Swal.fire("Opps!", message, "error");
  //     }
  //   } catch (error) {
  //     const errMsg = HttpError.getError(error);
  //     Swal.fire("Opps!", errMsg, "error");
  //   }
  //   loadingCtx.stopLoading();
  // };

  const changeStatusHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.changeStatus(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const deleteUserHandler = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await UserService.deleteUser(token, id);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [openUserDetailsDialog, setOpenUserDetailsDialog] = useState(false);
  const [userData, setUserData] = useState();

  return (
    <React.Fragment>
      <Templete t1="Home" t2="User List">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">User List</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2 mb-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setKycStatus(e.target.value);
                                  }}
                                >
                                  <option value={kycStatus ? kycStatus : ""}>
                                    {kycStatus
                                      ? kycStatus
                                      : "Select KYC Status"}
                                  </option>
                                  <option value="Verified">Verified</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Rejected">Rejected</option>
                                  <option value="Not Applied">
                                    Not Applied
                                  </option>
                                  <option value="Suspended">Suspended</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setStatus(e.target.value);
                                  }}
                                >
                                  <option value="">Select Status</option>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setUserType(e.target.value);
                                  }}
                                >
                                  <option value="">Select User Type</option>
                                  <option value="Retailer">Retailer</option>
                                  <option value="Distributor">
                                    Distributor
                                  </option>
                                  <option value="Master Distributor">
                                    Master Distributor
                                  </option>
                                  <option value="Reseller">Reseller</option>
                                  <option value="Master Reseller">
                                    Master Reseller
                                  </option>
                                  <option value="Customer">Customer</option>
                                  <option value="Outlet">Outlet</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    setState(e.target.value);
                                  }}
                                >
                                  <option>Select State</option>
                                  {states &&
                                    states.map((e, key) => {
                                      return (
                                        <option key={key} value={e.value}>
                                          {e.label}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="ID"
                                  onChange={(e) => {
                                    setId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="User ID"
                                  onChange={(e) => {
                                    setUserId(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search Text"
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                class="btn btn-primary mr-4"
                                onClick={() => {
                                  fetchUsers();
                                }}
                              >
                                <i class="fa fa-search"></i> Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <DataTableComponent
                      noHeader={true}
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={count}
                      paginationPerPage={size}
                      onChangePage={(page) => {
                        setPage(page);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <UserDetailsDialog
        openUserDetailsDialog={openUserDetailsDialog}
        setOpenUserDetailsDialog={setOpenUserDetailsDialog}
        userData={userData}
      />
    </React.Fragment>
  );
};

export default UserList;
