import React from "react";
import { Redirect } from "react-router-dom";

import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import AddUser from "../pages/Admin/ManageAgent/AddUser";
import UserList from "../pages/Admin/ManageAgent/UserList";
import EditUser from "../pages/Admin/ManageAgent/EditUser";
import UserDetails from "../pages/Admin/ManageAgent/UserDetails";
import WelcomeLetter from "../pages/Admin/ManageAgent/WelcomeLetter";
import ResetLoginDetails from "../pages/Admin/ManageAgent/ResetLoginDetails";
import UserProfile from "../pages/Admin/ManageAgent/UserProfile";
import AddPackage from "../pages/Admin/CommercialPackage/AddPackage";
import PackageList from "../pages/Admin/CommercialPackage/PackageList";
import EditPackage from "../pages/Admin/CommercialPackage/EditPackage";
import AddNewInvestment from "../pages/Admin/Investment/AddNewInvestment";
import InvestmentHistory from "../pages/Admin/Investment/InvestmentHistory";
import PendingInvtRequest from "../pages/Admin/Investment/PendingInvtRequest";
import Logout from "../pages/Admin/Logout/Logout";
import UserListTreeView from "../pages/Admin/ManageAgent/UserListTreeView";
import ActiveInvestments from "../pages/Admin/ManageAgent/ActiveInvestments";
import WithdrawalHistory from "../pages/Admin/WithdrawalModule/WithdrawalHistory";
import PendingWithdrawalRequest from "../pages/Admin/WithdrawalModule/PendingWithdrawalRequest";
import CreditTransactionHistory from "../pages/Admin/CreditModule/CreditTransactionHistory";
import AddCreditTransaction from "../pages/Admin/CreditModule/AddCreditTransaction";
import TeamInvestmentHistory from "../pages/Admin/Investment/TeamInvestmentHistory";
import EditInvestment from "../pages/Admin/Investment/EditInvestment";
import DbBackup from "../pages/Admin/DbBackup/DbBackup";
import InvestmentReceipt from "../pages/Admin/Investment/InvestmentReceipt";
import XlsCommissionBalance from "../pages/Admin/Reports/XlsCommissionBal";
import AddNotice from "../pages/Admin/ManageNotice/AddNotice";
import EditNotice from "../pages/Admin/ManageNotice/EditNotice";
import NoticeList from "../pages/Admin/ManageNotice/NoticeList";
import AddExpense from "../pages/Admin/ExpenseModule/AddExpense";
import EditExpense from "../pages/Admin/ExpenseModule/EditExpense";
import ExpenseHistory from "../pages/Admin/ExpenseModule/ExpenseHistory";
import RechargeReport from "../pages/Admin/Recharge/RechargeReport";
import LiveRechargeApi from "../pages/Admin/Recharge/LiveRechargeReport";
import DisputeRechargeReport from "../pages/Admin/Recharge/DisputeRechargeReport";
import SwitchRechargeApi from "../pages/Admin/Recharge/SwitchRechargeApi";
import NsdlPanCardReport from "../pages/Admin/NsdlPanCard/NsdlPanCardReport";
import UtiPanCardReport from "../pages/Admin/UtiPanCard/UtiPanCardReport";
import PgReport from "../pages/Admin/PG/PgReport";
import TotalWalletBalance from "../pages/Admin/Wallet/TotalWalletBalance";
import WalletTransactions from "../pages/Admin/Wallet/WalletTransactions";
import InsuranceReport from "../pages/Admin/Insurance/InsuranceReport";
import PrepaidCardUserList from "../pages/Admin/Services/PrepaidCard/UserList";
import PrepaidCardList from "../pages/Admin/Services/PrepaidCard/CardList";
import WalletTopup from "../pages/Admin/Wallet/WalletTopup";
import WalletTopupHistory from "../pages/Admin/Wallet/WalletTopupHistory";
import PpiTopup from "../pages/Admin/Services/PrepaidCard/PPITopup";
import AddLeadProduct from "../pages/Admin/LeadManagement/AddLeadProduct";
import LeadProductList from "../pages/Admin/LeadManagement/LeadProductList";
import EditLeadProduct from "../pages/Admin/LeadManagement/EditLeadProduct";
import SetCommercial from "../pages/Admin/CommercialPackage/SetCommercial";
import FundRequestHistory from "../pages/Admin/FundRequest/FundRequestHistory";
import PendingFundRequest from "../pages/Admin/FundRequest/PendingFundRequest";
import setOperatorCode from "../pages/Admin/Recharge/SetOperatorCode";
import RechargeApiList from "../pages/Admin/Recharge/RechargeApi/RechargeApiList";
import AddRechargeApi from "../pages/Admin/Recharge/RechargeApi/AddRechargeApi";
import EditRechargeApi from "../pages/Admin/Recharge/RechargeApi/EditRechargeApi";
import Settings from "../pages/Admin/Settings/Settings";
import CommissionPaymentSummary from "../pages/Admin/Wallet/CommissionPaymentSummary";

const adminAuthProtectedRoutes = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/add_user", component: AddUser },
  { path: "/admin/user_list", component: UserList },
  { path: "/admin/user_list_tree_view", component: UserListTreeView },
  { path: "/admin/edit_user/:userId", component: EditUser },
  { path: "/admin/user_details/:userId", component: UserDetails },
  { path: "/admin/welcome_letter/:userId", component: WelcomeLetter },
  { path: "/admin/reset_login_details/:userId", component: ResetLoginDetails },
  { path: "/admin/user_profile/:userId", component: UserProfile },
  { path: "/admin/active_investments/:id", component: ActiveInvestments },
  { path: "/admin/add_package", component: AddPackage },
  { path: "/admin/package_list", component: PackageList },
  { path: "/admin/edit_package/:id", component: EditPackage },
  { path: "/admin/set_commercial", component: SetCommercial },
  { path: "/admin/add_new_investment", component: AddNewInvestment },
  { path: "/admin/investment_history", component: InvestmentHistory },
  { path: "/admin/investment_receipt/:id", component: InvestmentReceipt },
  {
    path: "/admin/team_investment_history",
    component: TeamInvestmentHistory,
  },
  { path: "/admin/pending_invt_request", component: PendingInvtRequest },
  { path: "/admin/withdrawal_history", component: WithdrawalHistory },
  {
    path: "/admin/pending_withdrawal_request",
    component: PendingWithdrawalRequest,
  },
  {
    path: "/admin/credit_transaction_history",
    component: CreditTransactionHistory,
  },
  {
    path: "/admin/add_credit_transaction",
    component: AddCreditTransaction,
  },
  {
    path: "/admin/edit_investment/:id",
    component: EditInvestment,
  },
  {
    path: "/admin/wallet_topup/:id",
    component: WalletTopup,
  },
  {
    path: "/admin/wallet_topup_history",
    component: WalletTopupHistory,
  },
  {
    path: "/admin/commission_payment_summary",
    component: CommissionPaymentSummary,
  },
  {
    path: "/admin/fund_request_history",
    component: FundRequestHistory,
  },
  {
    path: "/admin/pending_fund_request",
    component: PendingFundRequest,
  },
  { path: "/admin/db_backup", component: DbBackup },

  { path: "/admin/xls_commission_balance", component: XlsCommissionBalance },

  { path: "/admin/add_notice", component: AddNotice },
  { path: "/admin/notice_list", component: NoticeList },
  { path: "/admin/edit_notice/:id", component: EditNotice },

  { path: "/admin/add_expense", component: AddExpense },
  { path: "/admin/expense_history", component: ExpenseHistory },
  { path: "/admin/edit_expense/:id", component: EditExpense },

  { path: "/admin/recharge_report", component: RechargeReport },
  { path: "/admin/live_recharge_report", component: LiveRechargeApi },
  { path: "/admin/dispute_recharge_report", component: DisputeRechargeReport },
  { path: "/admin/switch_recharge_api", component: SwitchRechargeApi },
  { path: "/admin/set_operator_code", component: setOperatorCode },
  { path: "/admin/add_recharge_api", component: AddRechargeApi },
  { path: "/admin/edit_recharge_api/:id", component: EditRechargeApi },
  { path: "/admin/recharge_api_list", component: RechargeApiList },

  { path: "/admin/nsdl_pancard_report", component: NsdlPanCardReport },
  { path: "/admin/uti_pancard_report", component: UtiPanCardReport },
  { path: "/admin/insurance_report", component: InsuranceReport },
  { path: "/admin/pg_report", component: PgReport },
  { path: "/admin/total_wallet_balance", component: TotalWalletBalance },
  { path: "/admin/wallet_transactions", component: WalletTransactions },

  { path: "/admin/prepaid_card_list", component: PrepaidCardList },
  { path: "/admin/ppi_user_list", component: PrepaidCardUserList },
  {
    path: "/admin/ppi_topup/:id",
    component: PpiTopup,
  },

  { path: "/admin/add_lead_product", component: AddLeadProduct },
  { path: "/admin/lead_product_list", component: LeadProductList },
  { path: "/admin/edit_lead_product/:id", component: EditLeadProduct },

  {
    path: "/admin/settings",
    component: Settings,
  },
  { path: "/admin/logout", component: Logout },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admin_login" />,
  },
];

export { adminAuthProtectedRoutes };
