import React, { useState, useEffect, useContext } from "react";
import DataTableComponent from "react-data-table-component";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Chip } from "@material-ui/core";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";
import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import ProductService from "../../../services/Admin/ProductService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const CommissionPaymentSummary = () => {
  const loadingCtx = useContext(LoadingContext);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [productId, setProductId] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [products, setProducts] = React.useState();

  const fetchProducts = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await ProductService.fetchAll(token);

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setProducts(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const fetchData = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const data = await WalletService.commissionPaymentSummary(
        token,
        fromDate,
        toDate,
        productId
      );

      const status = data.status;
      const message = data.message;

      if (status === "SUCCESS") {
        setData(data.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Commission Payment Summary">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Commission Payment Summary</h3>
                  </div>

                  <div class="card-body">
                    <div class="row mt-2">
                      <div class="col-md-12 mx-auto">
                        <form autoComplete="off">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="From Date"
                                  onChange={(e) => {
                                    setFromDate(e.target.value);
                                  }}
                                  defaultValue={fromDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <input
                                  type="date"
                                  class="form-control"
                                  placeholder="To Date"
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  defaultValue={toDate}
                                />
                              </div>
                            </div>

                            <div class="col-sm-3">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  onChange={(e) => {
                                    setProductId(
                                      e.target.value ? e.target.value : ""
                                    );
                                  }}
                                >
                                  <option value="">Select Product</option>
                                  {products &&
                                    products.map((e, key) => {
                                      return (
                                        <option key={key} value={e.id}>
                                          {e.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>

                            <div
                              class="col-sm-3"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                class="btn btn-primary mr-4"
                                onClick={() => {
                                  fetchData();
                                }}
                              >
                                <i class="fa fa-search"></i> Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {data && (
                      <div
                        className="table-responsive"
                        style={{ marginTop: "30px" }}
                      >
                        <table
                          className="table table-hover"
                          //  style={{ border: "1px solid black" }}
                        >
                          <tbody>
                            <tr>
                              <td>Retailer Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_rt_amount}
                              </td>
                            </tr>

                            <tr>
                              <td>Distributor Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_dt_c_amount}
                              </td>
                            </tr>

                            <tr>
                              <td>Master Distributor Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_md_c_amount}
                              </td>
                            </tr>

                            <tr>
                              <td>Reseller Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_rs_c_amount}
                              </td>
                            </tr>

                            <tr>
                              <td>Master Reseller Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_mrs_c_amount}
                              </td>
                            </tr>

                            <tr class="table-primary">
                              <td>Total Users Commission</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_c_amount}
                              </td>
                            </tr>

                            <tr class="table-success">
                              <td>Net Admin Earning</td>
                              <td>:</td>
                              <td
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                ₹{data.total_admin_c_amount}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default CommissionPaymentSummary;
