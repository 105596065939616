import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";

import Templete from "../Templete/Templete";
import common from "../../../utill/Common";

import AuthService from "../../../services/Admin/AuthService";
import WalletService from "../../../services/Admin/WalletService";
import NsdlPanCardService from "../../../services/Admin/NsdlPanCardService";
import RechargeService from "../../../services/Admin/RechargeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const Dashboard = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState(false);

  useEffect(() => {
    getBalance();
    // getNsdlApiBalance();
    getRechargeSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBalance = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.getTotalBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        if (message === "Unauthorized Access") {
          AuthService.logout();
          window.location.href = "/admin_login";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const [nsdlBal, setNsdlBal] = useState(false);

  const getNsdlApiBalance = async () => {
    try {
      const token = AuthService.getAccessToken();

      const responseData = await NsdlPanCardService.getApiBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setNsdlBal(responseData.data.amount);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
  };

  const [isLoading, setIsloading] = useState(false);

  const [activeBtn, setActiveBtn] = useState(1);

  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));

  const [rechargeSummaryData, setRechargeSummaryData] = useState();

  const getRechargeSummary = async (days = null) => {
    setIsloading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.summary(
        token,
        fromDate,
        toDate,
        null,
        null,
        days
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setRechargeSummaryData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsloading(false);
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Dashboard">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-2 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h4>
                      <b>₹{data ? data.main_wallet : "0"}</b>
                    </h4>
                    <p>Net Main Wallet Balance</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a
                    href="/admin/total_wallet_balance"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="small-box bg-primary">
                  <div class="inner">
                    <h4>
                      <b>
                        ₹{data ? data.today_wallet_topup[0].total_amount : "0"}
                      </b>
                    </h4>
                    <p>Today Wallet Topup</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/commission_payment_summary"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h4>
                      <b>
                        ₹{data ? data.today_admin_earning[0].total_amount : "0"}
                      </b>
                    </h4>
                    <p>Today Admin Earning</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/commission_payment_summary"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              {/* <div class="col-lg-2 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h4>
                      <b>
                        {data.pending_pancard_coupon
                          ? data.pending_pancard_coupon.rows.length > 0
                            ? data.pending_pancard_coupon.rows[0].total_amount +
                              " [" +
                              data.pending_pancard_coupon.count +
                              "]"
                            : "0" +
                              " [" +
                              data.pending_pancard_coupon.count +
                              "]"
                          : "0"}
                      </b>
                    </h4>
                    <p>Pending PAN Coupon</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/uti_pancard_report?status=Pending"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div> */}

              <div class="col-lg-2 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h4>
                      <b>
                        {data.pending_recharge
                          ? data.pending_recharge.rows.length > 0
                            ? "₹" +
                              data.pending_recharge.rows[0].total_amount +
                              " [" +
                              data.pending_recharge.count +
                              "]"
                            : "₹0" + " [" + data.pending_recharge.count + "]"
                          : "0"}
                      </b>
                    </h4>

                    <p>Pending Recharge</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/recharge_report?status=Pending"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h4>
                      <b>
                        {data.pending_dispute
                          ? data.pending_dispute.rows.length > 0
                            ? "₹" +
                              data.pending_dispute.rows[0].total_amount +
                              " [" +
                              data.pending_dispute.count +
                              "]"
                            : "0" + " [" + data.pending_dispute.count + "]"
                          : "0"}
                      </b>
                    </h4>

                    <p>Pending Dispute</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/dispute_recharge_report"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-6">
                <div class="small-box bg-secondary">
                  <div class="inner">
                    <h4>
                      <b>
                        {data.pending_fund_req
                          ? data.pending_fund_req.rows.length > 0
                            ? "₹" +
                              data.pending_fund_req.rows[0].total_amount +
                              " [" +
                              data.pending_fund_req.count +
                              "]"
                            : "0" + " [" + data.pending_fund_req.count + "]"
                          : "0"}
                      </b>
                    </h4>

                    <p>Pending Fund Request</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a
                    href="/admin/pending_fund_request"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Recharge Sales Report</h5>
                    <div className="card-tools">
                      <div
                        class="btn-group"
                        role="group"
                        data-toggle="button"
                        aria-label="Basic example"
                      >
                        <button
                          type="button"
                          class="btn btn-sm btn-primary"
                          className={
                            activeBtn === 1
                              ? "btn btn-sm btn-primary"
                              : "btn btn-sm btn-default"
                          }
                          onClick={() => {
                            setActiveBtn(1);
                            getRechargeSummary();
                          }}
                        >
                          Today
                        </button>
                        <button
                          type="button"
                          className={
                            activeBtn === 7
                              ? "btn btn-sm btn-primary"
                              : "btn btn-sm btn-default"
                          }
                          onClick={() => {
                            setActiveBtn(7);
                            getRechargeSummary(7);
                          }}
                        >
                          7 Days
                        </button>
                        <button
                          type="button"
                          className={
                            activeBtn === 15
                              ? "btn btn-sm btn-primary"
                              : "btn btn-sm btn-default"
                          }
                          onClick={() => {
                            setActiveBtn(15);
                            getRechargeSummary(15);
                          }}
                        >
                          15 days
                        </button>
                        <button
                          type="button"
                          className={
                            activeBtn === 30
                              ? "btn btn-sm btn-primary"
                              : "btn btn-sm btn-default"
                          }
                          onClick={() => {
                            setActiveBtn(30);
                            getRechargeSummary(30);
                          }}
                        >
                          30 days
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div class="row mt-2">
                      <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box">
                          <span class="info-box-icon bg-success elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">SUCCESS</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && rechargeSummaryData
                                ? rechargeSummaryData.success.rows.length > 0
                                  ? "₹" +
                                    rechargeSummaryData.success.rows[0]
                                      .total_amount +
                                    " [" +
                                    rechargeSummaryData.success.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    rechargeSummaryData.success.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-warning elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">PENDING</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && rechargeSummaryData
                                ? rechargeSummaryData.pending.rows.length > 0
                                  ? "₹" +
                                    rechargeSummaryData.pending.rows[0]
                                      .total_amount +
                                    " [" +
                                    rechargeSummaryData.pending.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    rechargeSummaryData.pending.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-danger elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">FAILED</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && rechargeSummaryData
                                ? rechargeSummaryData.failed.rows.length > 0
                                  ? "₹" +
                                    rechargeSummaryData.failed.rows[0]
                                      .total_amount +
                                    " [" +
                                    rechargeSummaryData.failed.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    rechargeSummaryData.failed.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-4">
                          <span class="info-box-icon bg-primary elevation-1">
                            <i class="fas fa-arrow-right"></i>
                          </span>
                          <div class="info-box-content">
                            <span class="info-box-text">TOTAL</span>
                            <span class="info-box-number">
                              {isLoading && (
                                <div class="spinner-border spinner-border-sm"></div>
                              )}
                              {!isLoading && rechargeSummaryData
                                ? rechargeSummaryData.total.rows.length > 0
                                  ? "₹" +
                                    rechargeSummaryData.total.rows[0]
                                      .total_amount +
                                    " [" +
                                    rechargeSummaryData.total.count +
                                    "]"
                                  : "₹0" +
                                    " [" +
                                    rechargeSummaryData.total.count +
                                    "]"
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {common.companyName == "FORMAX" && (
              <div className="row">
                <div class="col-lg-3 col-6">
                  <div class="small-box bg-primary">
                    <div class="inner">
                      <h3>{nsdlBal ? "₹" + nsdlBal : "null"}</h3>
                      <small>
                        {toWords.convert(nsdlBal ? parseInt(nsdlBal) : 0)}
                      </small>
                      <p>NSDL API Balance</p>
                    </div>
                    <div class="icon">
                      <i class="ion ion-pie-graph"></i>
                    </div>
                    <a
                      href="/admin/nsdl_pancard_report"
                      class="small-box-footer"
                    >
                      More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default Dashboard;
