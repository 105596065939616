import React, { useRef, useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import WalletService from "../../../services/User/WalletService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import UPIGateway from "./PG-Model/UPIGateway";
import AllInOneUPIGateway from "./PG-Model/AllInOneUpiGateway";

const AddMoney = () => {
  const loadingCtx = useContext(LoadingContext);

  const [defaultPG, setDefaultPG] = useState();

  useEffect(() => {
    getDefaultPG();
  }, []);

  const getDefaultPG = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await WalletService.defaultPG(token);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const default_pg = responseData.data.default_pg;
        setDefaultPG(default_pg);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Add Money">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Add Money</h3>
                  </div>

                  {defaultPG === "UPI GATEWAY" && <UPIGateway />}
                  {defaultPG === "ALL IN ONE UPI GATEWAY" && (
                    <AllInOneUPIGateway />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default AddMoney;
