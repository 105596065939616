import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import classnames from "classnames";
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ListGroup,
  ListGroupItem,
  Label,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import AuthService from "../../../../services/User/AuthService";
import RechargeService from "../../../../services/User/RechargeService";
import HttpError from "../../../../utill/HttpError";
import LoadingContext from "../../../../store/loading-context";

const ViewPlansDialog = (props) => {
  const circle = props.circle;

  function handleCircleChange(e) {
    props.setCircle(e.value);
    fetchPlans(e.value);
  }

  const data = props.plansData;

  const circles = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar / Jharkhand", label: "Bihar / Jharkhand" },
    { value: "Chennai", label: "Chennai" },
    { value: "Delhi NCR", label: "Delhi NCR" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu Kashmir", label: "Jammu Kashmir" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Kolkata", label: "Kolkata" },
    {
      value: "Madhya Pradesh / Chhattisgarh",
      label: "Madhya Pradesh / Chhattisgarh",
    },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "North East", label: "North East" },
    { value: "Orissa", label: "Orissa" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "UP East", label: "UP East" },
    { value: "UP West", label: "UP West" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const loadingCtx = useContext(LoadingContext);

  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (circle) {
      fetchPlans(circle);
    }
  }, [circle]);

  const fetchPlans = async (circle) => {
    if (!circle) {
      return;
    }

    props.handleSetPlansData();
    setIsLoading(true);

    try {
      const token = AuthService.getAccessToken();

      const responseData = await RechargeService.fetchPlans(
        token,
        props.operator ? props.operator.label : "",
        circle
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        props.handleSetPlansData(responseData.data);
        // Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      props.handleOpen();
      Swal.fire("Opps!", errMsg, "error");
    }

    setIsLoading(false);
  };

  // Border Top Nav
  const [topBorderTab, settopBorderTab] = useState("1");
  const topBordertoggle = (tab) => {
    if (topBorderTab !== tab) {
      settopBorderTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={props.open} toggle={props.handleClose}>
        <div className="modal-header">
          <h5 className="modal-title" id="myModalLabel">
            Browse Plans
          </h5>
          <img
            src={props.operator ? props.operator.image : ""}
            alt="opt-logo"
            height="50px"
          />
        </div>

        <ModalBody>
          <div className="mb-3">
            <Select
              defaultValue={{ label: props.circle, value: props.circle }}
              onChange={(e) => {
                handleCircleChange(e);
              }}
              options={circles}
            />
          </div>

          {loading && (
            <div className="text-center">
              <Spinner color="primary"> </Spinner>
            </div>
          )}

          {data && (
            <div>
              <Nav
                tabs
                className="nav nav-tabs nav-justified nav-border-top nav-border-top-success mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "1" })}
                    onClick={() => {
                      topBordertoggle("1");
                    }}
                  >
                    Unlimited
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "2" })}
                    onClick={() => {
                      topBordertoggle("2");
                    }}
                  >
                    Talktime
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "3" })}
                    onClick={() => {
                      topBordertoggle("3");
                    }}
                  >
                    Data
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "4" })}
                    onClick={() => {
                      topBordertoggle("4");
                    }}
                  >
                    SMS
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "5" })}
                    onClick={() => {
                      topBordertoggle("5");
                    }}
                  >
                    Roaming
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "6" })}
                    onClick={() => {
                      topBordertoggle("6");
                    }}
                  >
                    FRC
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: topBorderTab === "7" })}
                    onClick={() => {
                      topBordertoggle("7");
                    }}
                  >
                    STV
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={topBorderTab} className="text-muted">
                <TabPane tabId="1" id="nav-border-justified-home">
                  <ListGroup>
                    {data &&
                      data.unlimited.map((value, key) => (
                        <ListGroupItem
                          tag="a"
                          to="#"
                          className="list-group-item-action"
                          key={key}
                          onClick={() => {
                            props.handleSetAmount(value.price);
                            props.handleClose();
                          }}
                        >
                          <Button
                            color="primary"
                            className="float-end ms-3 rounded-pill"
                          >
                            ₹{value.price}
                          </Button>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 ">
                              <h6 className="list-title fs-12 mb-1">
                                {value.description}
                              </h6>
                              <p className="list-text mb-0 fs-12">
                                {value.validity}
                              </p>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                  </ListGroup>
                </TabPane>

                <TabPane tabId="2" id="nav-border-justified-profile">
                  {data &&
                    data.talktime.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>

                <TabPane tabId="3" id="nav-border-justified-messages">
                  {data &&
                    data.data.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>

                <TabPane tabId="4" id="nav-border-justified-messages">
                  {data &&
                    data.sms.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>

                <TabPane tabId="5" id="nav-border-justified-messages">
                  {data &&
                    data.roaming.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>

                <TabPane tabId="6" id="nav-border-justified-messages">
                  {data &&
                    data.frc.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>

                <TabPane tabId="7" id="nav-border-justified-messages">
                  {data &&
                    data.stv.map((value, key) => (
                      <ListGroupItem
                        tag="a"
                        to="#"
                        className="list-group-item-action"
                        key={key}
                        onClick={() => {
                          props.handleSetAmount(value.price);
                          props.handleClose();
                        }}
                      >
                        <Button
                          color="primary"
                          className="float-end ms-3 rounded-pill"
                        >
                          ₹{value.price}
                        </Button>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p className="list-title fs-12 mb-1">
                              {value.description}
                            </p>
                            <p className="list-text mb-0 fs-12">
                              {value.validity}
                            </p>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))}
                </TabPane>
              </TabContent>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={props.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ViewPlansDialog;
