import React, { useRef, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import Countdown from "react-countdown";

import AuthService from "../../../../services/User/AuthService";
import WalletService from "../../../../services/User/WalletService";
import LoadingContext from "../../../../store/loading-context";
import HttpError from "../../../../utill/HttpError";
import { useHistory } from "react-router-dom";

const QRCodeDialog = (props) => {
  const history = useHistory();
  const loadingCtx = useContext(LoadingContext);

  const orderId = props.orderId;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Logs every 10 second");
      checkTxnStatus();
    }, 10000);

    return () => clearInterval(interval);
  }, [orderId]);

  const checkTxnStatus = async () => {
    if (!orderId || orderId == "") {
      return;
    }

    try {
      const token = AuthService.getAccessToken();

      let responseData = await WalletService.txnStatus(token, orderId);

      if (!responseData) {
        return;
      }

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const status = responseData.data.status;
        const ref_id = responseData.data.ref_id;
        const amount = responseData.data.amount;

        props.setOrderId("");
        props.handleClose("");

        Swal.fire("Success!", message, "success");
      } else {
        //Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      props.handleClose();
      // Render a completed state
      return <p>Completed</p>;
    } else {
      // Render a countdown
      return (
        <span>
          QR Code is valid for{" "}
          <b style={{ color: "red" }}>
            {minutes}:{seconds}
          </b>{" "}
          minutes
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.open} onHide={props.handleClose}>
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">UPI QR</h4>
            <button
              type="button"
              class="close"
              onClick={props.handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className="text-center plan-btn mt-2 mb-2">
              <img src="/assets/img/logo.png" alt="logo" height="50px" />
              <h5 className="mt-4">Scan this QR code to Add Money</h5>
              {props.upiIntent && (
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 150,
                    width: "100%",
                    paddingTop: "10px",
                    paddingBottom: "15px",
                  }}
                >
                  <QRCode
                    size={500}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={props.upiIntent}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              )}
              <h6 className="mb-1">Scan &amp; Pay with any BHIM UPI App</h6>
              <img src="/assets/img//upi_apps.png" alt="logo" height="80px" />

              <div style={{ paddingTop: "5px" }}>
                <Countdown
                  date={Date.now() + 60000 * 5}
                  intervalDelay={0}
                  precision={3}
                  renderer={renderer}
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-default"
              onClick={props.handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default QRCodeDialog;
